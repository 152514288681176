<template>
  <div class="main_lci_wrapper ">
    <div class="main_layout d-flex">
      <div class="cards_and_logo">
        <div class="render_action">
          <img src="@/assets/new-theme/PLC-Logo.svg" alt="" />

          <img
            v-if="hideXIcon"
            @click="hideAndShowSidebar"
            role="button"
            src="@/assets/new-theme/lci/x-close.png"
            alt="x-close"
          />
        </div>

        <div v-if="hideShowSidebar == false">
          <div class="cards_wrapper" v-for="(item, i) in cardItems" :key="i">
            <LciCard :item="item"></LciCard>
          </div>
        </div>
        <div
          class="mt-5 absolute_question_mark"
          v-if="!hideXIcon"
          :class="{ make_absolute: hideShowSidebar == true }"
        >
          <div
            @click="hideAndShowSidebar"
            role="button"
            class="mark_tooltip"
            v-tooltip.top-center="`${localization('Useful tips and support')}`"
          >
            <img src="@/assets/new-theme/icons/help-circle@2x.png" alt="" />
          </div>
        </div>
      </div>
      <!-- Tabs -->
      <div class="tabs_wrapper">
        <div class="step_number_and_questions_number">
          <img
            role="button"
            src="@/assets/new-theme/icons/arrow-narrow-left-active-Button.png"
            alt=""
          />
          <span class="st_number_value"
            >Personal Work Profile - Step {{ this.currentTabIndex + 1 }}
          </span>
          <span class="st_questions_number">12 Sentences</span>
        </div>
        <!-- Start Questions -->
        <div class="tabs_steps mt-4">
          <span
            v-for="tab in tabs"
            :key="tab.index"
            class="d-block step"
            :class="{ active: tab.index == currentTabIndex }"
          ></span>
        </div>
        <!-- End Questions -->
        <!-- Start Loop Collapsable Questions -->

        <div
          class="global_theme_ui_collapse main_profile_personalize_and_collapse"
        >
          <h4>
            {{ tabs[currentTabIndex].title }}
          </h4>

          <el-collapse
            class="mt-3 customization_for_collapse"
            v-model="panel"
            multiple
          >
            <div
              class="main_child "
              v-for="(attribute, i) in tabs[currentTabIndex].question"
              :key="i"
              style="border : 2px solid #d0d5dd"
            >
              <el-collapse-item
                :name="`${i}`"
                class=""
                :class="{ break_section: i == 'Precision' }"
              >
                <template #title>
                  <div class="custom_header_of_collapse">
                    <img
                      src="@/assets/new-theme/attributes-images/sequence.png"
                      alt="sequence"
                      v-if="i == 'Sequence'"
                    />
                    <img
                      src="@/assets/new-theme/attributes-images/precision.png"
                      alt="precision"
                      v-if="i == 'Precision'"
                    />
                    <img
                      src="@/assets/new-theme/attributes-images/technical_reasoning.png"
                      alt="technical_reasoning"
                      v-if="i == 'Technical Reasoning'"
                    />
                    <img
                      src="@/assets/new-theme/attributes-images/confluence.png"
                      alt="confluence"
                      v-if="i == 'Confluence'"
                    />
                    <span class="ml-2 mr-2">{{ i }}</span>
                  </div>
                </template>
                <!-- Start Checkboxes -->
                <div class="p-3">
                  <el-collapse class="collapse_checkboxes">
                    <div v-for="(item, index) in attribute" :key="index">
                      <div
                        class="checkboxes_wrapper"
                        v-for="(val, k) in item"
                        :key="k"
                      >
                        <el-checkbox-group
                          class=""
                          v-model="selected"
                          v-if="val"
                        >
                          <el-checkbox
                            v-if="val"
                            :label="val.id"
                            @change="updateSelectedAttributes"
                          >
                            {{ val.statement }}
                          </el-checkbox>
                        </el-checkbox-group>
                        <audio controls id="audio" class="d-none">
                          <source id="audio-source" type="audio/mp3" />
                        </audio>
                        <span
                          class="span_img_wrapper hide_element_while_printing"
                          role="button"
                          @click="text_speech_audio(val.statement)"
                        >
                          <img
                            src="@/assets/new-theme/attributes-images/volume.png"
                            alt="volume"
                          />
                        </span>
                      </div>
                    </div>
                  </el-collapse>
                </div>
              </el-collapse-item>
            </div>
          </el-collapse>

          <!-- Start Buttons For Next And Previous -->
          <div class="mt-5 d-flex justify-content-center ">
            <button
              v-if="currentTabIndex > 0"
              @click="prevTab"
              class="prev_next"
            >
              <img
                src="@/assets/new-theme/icons/arrow-narrow-left-active-Button.png"
                alt="edit icon"
              />
            </button>
            <button
              @click="nextTab"
              v-if="currentTabIndex < tabs.length - 1"
              class="lci_btn save mx-4"
            >
              Save and Continue
            </button>

            <button
              @click="nextTab"
              v-if="currentTabIndex < tabs.length - 1"
              class="prev_next"
            >
              <img
                src="@/assets/new-theme/icons/arrow-narrow-right-Disable-Button.png"
                alt="edit icon"
              />
            </button>
            <button
              v-if="currentTabIndex === tabs.length - 1"
              class="lci_btn save  mx-4"
            >
              Save and Continue
            </button>
          </div>
        </div>
        <!-- End Loop Collapsable Questions -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PwpQuestions",
  data() {
    return {
      hideShowSidebar: false,
      hideXIcon: false,
      cardItems: [
        {
          src: require("@/assets/new-theme/pwp-1.png"),
          title: "Career matching",
          description:
            "Understanding your behaviors and career goals provides us with insights to suggest personalized strategies that helps you in career opportunity."
        },
        {
          src: require("@/assets/new-theme/pwp-2.png"),
          title: "Take the time you need",
          description:
            "While there are no right or wrong answers, there are answers that are more accurate to who you are."
        },
        {
          src: require("@/assets/new-theme/pwp-3.png"),
          title: "Be accurate and detailed",
          description:
            "Try to be as accurate and detailed as possible when selecting your answer."
        }
      ],
      tabs: [],
      active: 0,
      activeTabs: [0],
      currentTab: 0,
      // panel: [],
      currentTabIndex: 0,
      panel: [`Precision`, `Sequence`, "Technical Reasoning", "Confluence"],
      selected: []
    };
  },

  computed: {
    careerMatchStatementsQuestions() {
      return this.$store.getters["user/getCareerMatchStatementsQuestions"];
    },
    mainUserId() {
      return this.auth().id;
    }
  },
  methods: {
    hideAndShowSidebar() {
      this.hideShowSidebar = !this.hideShowSidebar;
      this.hideXIcon = !this.hideXIcon;
    },
    refresh(query) {
      this.$store
        .dispatch("user/handlerCareerMatchStatementsQuestions", {
          query: query
        })
        .then(res => {
          this.tabs = Object.entries(res.my_statements).map(
            ([title, question], index) => ({
              index: index,
              title, // The key, used as the question title
              question // The value, used as the question content
            })
          );
          // this.updatePanel(this.currentTabIndex);
          console.log("🚀 ~ this.tabs=Object.entries ~ this.tabs:", this.tabs);
        });
    },
    isActive(index) {
      return this.activeTabs.includes(index);
    },
    updatePanel(index) {
      // Update the panel array with the keys of the selected question's content
      this.currentTabIndex = index;
      // this.panel = Object.keys(this.tabs[index].question);
    },
    switchTab(index) {
      // this.updatePanel(index);
    },
    nextTab() {
      this.currentTabIndex++;
      console.log("🚀 ~ this.currentTabIndex:", this.currentTabIndex);
    },
    prevTab() {
      this.currentTabIndex--;
      console.log("🚀 ~ this.currentTabIndex:", this.currentTabIndex);
    },
    getMainUserData() {
      this.$store.dispatch("user/user", this.mainUserId).then(res => {
        console.log("🚀 ~ this.$store.dispatch ~ res:", res);
        console.log("🚀 ~ this.$store.dispatch ~ res:", this.selected);
        if (
          res.user.my_career_statements &&
          res.user.my_career_statements != null &&
          res.user.my_career_statements != false
        ) {
          this.selected = JSON.parse(res.user.my_career_statements);
        } else {
          this.selected = [];
        }
      });
    },
    updateSelectedAttributes() {
      this.$store
        .dispatch("attributes/updateCareerMatchStatements", {
          id: this.mainUserId,
          query: { my_career_statements: this.selected }
        })
        .then(() => {
          this.refresh();
          this.getMainUserData();
        });
    },
    text_speech_audio(any_text) {
      this.$store
        .dispatch("user/get_text_to_speech_audio", { text: any_text })
        .then(res => {
          let audioBlob = new Blob([res], { type: "audio/mpeg" });
          let audioURL = URL.createObjectURL(audioBlob);
          let audio = document.getElementById("audio");
          var source = document.getElementById("audio-source");
          source.src = audioURL;
          audio.load();
          audio.play();
        });
    },
    getKey(any_key) {
      return any_key;
    }
  },
  mounted() {
    this.refresh();
    this.getMainUserData();
  }
};
</script>

<style lang="scss" scoped>
.main_lci_wrapper {
  width: 100%;

  .render_action {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .main_layout {
    gap: 40px;
  }
  .cards_and_logo {
    background: #fcfcfd;
    max-width: 25%;
    min-width: 25%;
    position: relative;
    .absolute_question_mark {
      position: fixed;
      left: 10px;
      top: 82%;
      &.make_absolute {
        position: absolute;
        bottom: 0;
      }
    }
    .cards_wrapper {
      margin-top: 40px;
    }
  }
  // Tabs
  .tabs_wrapper {
    flex-grow: 1;
    // background: #eee;
    .step_number_and_questions_number {
      display: flex;
      align-items: center;
      gap: 10px;
      .st_number_value {
        color: #101828;
        font-size: 22px;
        font-weight: 500;
      }
      .st_questions_number {
        color: #667085;
        font-size: 14px;
      }
    }
    .tabs_steps {
      display: flex;
      justify-content: space-between;
      gap: 30px;
      .step {
        height: 4px;
        border-radius: 8px;
        background-color: #e9d7fe;
        flex-grow: 1;
        // width: 180px;
        &.active {
          background-color: #7f56d9;
        }
      }
    }
  }
}
// Buttons Next And Previous
.lci_btn {
  background-color: #f2f4f7;
  color: #667085;
  border-radius: 8px;
  padding: 12px 20px;
  border: 1px solid #eaecf0;
  width: 240px;
  &.save {
    background-color: #7f56d9;
    color: #fff;
  }
}
.prev_next {
  background-color: #f2f4f7;
  border-radius: 8px;
  padding: 12px 20px;
  border: 1px solid #eaecf0;
  color: #667085;
  width: 70px;
}
.mark_tooltip {
  border: 1px solid #d0d5dd;
  width: fit-content;
  padding: 12px;
  border-radius: 50%;
}
</style>

<style lang="scss">
.main_profile_personalize_and_collapse {
  .el-collapse {
    border: none !important;
  }
  .el-collapse-item__content {
    box-shadow: none !important;
    border: none !important;

    // @media print {
    //   padding-bottom: 0;
    // }
  }
  .el-collapse-item__wrap {
    border-bottom: none !important;
  }
  .el-collapse-item__content {
    padding-bottom: 0;
    font-size: 16px;
    line-height: inherit;
    color: #344054 !important;
  }
  .el-checkbox__inner {
    border: 1px solid #d0d5dd;
    border-radius: 6px;
    height: 20px;
    width: 20px;
  }
  .el-checkbox {
    align-items: flex-start;
    display: flex;
  }
  .el-checkbox__label,
  .el-checkbox is-checked {
    color: #344054 !important;
    font-weight: 400;
    font-size: 16px;

    // max-width: 98%;
    word-break: break-word !important;
    white-space: pre-wrap;
    // word-wrap: break-word;
    white-space: normal;
  }
  .el-checkbox__input.is-checked .el-checkbox__inner,
  .el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background-color: #6941c6;
    border-color: #6941c6;
  }
  .el-checkbox__inner::after {
    left: 7px;
    top: 4px;
    border-width: 2px;
  }
}
</style>
